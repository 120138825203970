import React from 'react';
import HCPPage from "../HCPPage/HCPPage"

export default function Fokina() {
  return (
    <div>
        <HCPPage />
    </div>
  )
}

import React from 'react';
import HCPPage from "./HCPPage";


export default function Haykina() {
  return (
    <div>
        <HCPPage />
    </div>
  )
}

import React from 'react';
import HCPPage from "./HCPPage";

export default function Poluboyarinova() {
  return (
    <div>
        <HCPPage />
    </div>
  )
}

export const photoGallerry = [
    {
        id: 1, 
        url: "https://i.postimg.cc/P55MpK5R/phglr-1.jpg",
    },

    {
        id: 2, 
        url: "https://i.postimg.cc/BQspsJ9s/phglr-2.jpg",
    },

    {
        id: 3, 
        url: "https://i.postimg.cc/7YVVZjwz/phglr-3.jpg",
    },

    {
        id: 4, 
        url: "https://i.postimg.cc/gjyKRThf/phglr-4.jpg",
    },

    {
        id: 5, 
        url: "https://i.postimg.cc/P5VQmZLr/phglr-5.jpg",
    },

    {
        id: 6, 
        url: "https://i.postimg.cc/j2YXDbDH/phglr-6.jpg",
    },

    
]
export const tableData = [
    {
        id: 1,
        name: "Сахарный диабет",
        descr: [
            {id: 1, message: "Сахарный диабет 1-го, 2-го и других типов"},
            {id: 2, message: "Диабетическая нефропатия"},
            {id: 3, message: "Диабетическая ретинопатия"},
            {id: 4, message: "Синдром диабетической стопы"},
            {id: 5, message: "Школы для обучения пациентов"},
        ],
    },

    {
        id: 2,
        name: "Заболевания щитовидной железы",
        descr: [
            {id: 1, message: "Узловой токсический (нетоксический) зоб"},
            {id: 2, message: "Диффузный токсический зоб"},
            {id: 3, message: "Тиреоидиты"},
            {id: 4, message: "Гипотиреоз"},
            {id: 5, message: "Эндокринная офтальмопатия щитовидной железы"},
        ],
    },
    
    {
        id: 3,
        name: "Патология паращитовидных желез",
        descr: [
            {id: 1, message: "Гиперпаратиреоз"},
            {id: 2, message: "Гипопаратиреоз"},
            {id: 3, message: "Псевдогипопаратиреоз, псевдопсевдогипопаратиреоз"},
        ],
    },

    {
        id: 4,
        name: "Заболевания надпочечников",
        descr: [
            {id: 1, message: "Надпочечниковая недостаточность"},
            {id: 2, message: "Синдром Иценко-Кушинга"},
            {id: 3, message: "Феохромоцитома"},
            {id: 4, message: "Гиперальдостеронизм"},
            {id: 5, message: "Врожденная дисфункция коры надпочечников"},
        ],
    },

    {
        id: 5,
        name: "Гипоталамо-гипофизарные заболевания",
        descr: [
            {id: 1, message: "Акромегалия"},
            {id: 2, message: "Синдром гиперпролактинемии"},
            {id: 3, message: "Болезнь Иценко-Кушинга"},
            {id: 4, message: "Гипофизарная недостаточность"},
            {id: 5, message: "Несахарный диабет"},
        ],
    },

    {
        id: 6,
        name: "Эндокринология-гинекология",
        descr: [
            {id: 1, message: "Бесплодие"},
            {id: 2, message: "Синдром поликистозных яичников"},
            {id: 3, message: "Гормональная контрацепция"},
            {id: 4, message: "Здоровье женщины в возрасте от 35 до 45 лет"},
            {id: 5, message: "Здоровье женщины в возрасте от 46 до 55 лет"},
            {id: 6, message: "Здоровье женщины в возрасте от 56 до 65 лет"},
            {id: 7, message: "Заместительная терапия женскими половыми гормонами"},
        ],
    },

    {
        id: 7,
        name: "Эндокринные нарушения при беременности",
        descr: [
            {id: 1, message: "Заболевания щитовидной железы"},
            {id: 2, message: "Послеоперационный гипопаратиреоз"},
            {id: 3, message: "Сахарный диабет"},
        ],
    },

    {
        id: 8,
        name: "Андрология",
        descr: [
            {id: 1, message: "Эректильная дисфункция"},
            {id: 2, message: "Гипер(гипо)гонадотропный гипогонадизм (синдром Клайнфельтера, синдром Каллманна и др)"},
        ],
    },

    {
        id: 9,
        name: "Функциональная диагностика",
        descr: [
            {id: 1, message: "УЗИ щитовидной железы"},
            {id: 2, message: "Тонкоигольная аспирационная биопсия и цитологическое исследование узлов щитовидной железы"},
            {id: 3, message: "Ультразвуковое дуплексное сканирование артерий и вен нижних конечностей"},
            {id: 4, message: "Холтеровское мониторирование ЭКГ"},
            {id: 5, message: "Суточное мониторирование артериального давления"},
        ],
    },
]

export const rowData = [
    {id: 1, message: "Избыточная масса тела, метаболический синдром, ожирение"},
    {id: 2, message: "Инсулинома, глюкагонома, гастринома, випома"},
    {id: 3, message: "Множественные эндокринные неоплазии"},
    {id: 4, message: "Аутоиммунные полигландулярные синдромы"},
    {id: 5, message: "Остеопороз"},
]
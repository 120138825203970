import React from 'react';
import HCPPage from "./HCPPage";

export default function Dzyuba() {
  return (
    <div>
      <HCPPage />
    </div>
  )
}

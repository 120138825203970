import React from 'react';
import HCPPage from './HCPPage';

export default function Amosova() {
  return (
    <div>
      <HCPPage/>
    </div>
  )
}
